import { render, staticRenderFns } from "./ToggleCheckbox.vue?vue&type=template&id=2bfce136&scoped=true"
import script from "./ToggleCheckbox.vue?vue&type=script&lang=js"
export * from "./ToggleCheckbox.vue?vue&type=script&lang=js"
import style0 from "./ToggleCheckbox.vue?vue&type=style&index=0&id=2bfce136&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2bfce136",
  null
  
)

export default component.exports