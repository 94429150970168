import { render, staticRenderFns } from "./AppAttachments.vue?vue&type=template&id=cb25d0d2&scoped=true"
import script from "./AppAttachments.vue?vue&type=script&lang=js"
export * from "./AppAttachments.vue?vue&type=script&lang=js"
import style0 from "./AppAttachments.vue?vue&type=style&index=0&id=cb25d0d2&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "cb25d0d2",
  null
  
)

export default component.exports